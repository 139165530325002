<template>
  <blank-layout>
    <div class="relative bg-gray-50 px-4 sm:px-6 lg:px-8">
      <div class="relative max-w-7xl mx-auto">
        <div class="text-center">
          <img
            src="@/assets/images/avn_logo.png"
            class="mx-auto h-32 my-4"
            alt
          >
          <h2
            class="text-3xl leading-9 tracking-tight font-medium text-gray-900 sm:text-4xl sm:leading-10"
          >
            Available Virtual Conferences
          </h2>
        </div>
        <Suspense>
          <template #default>
            <conference-cards />
          </template>
          <template #fallback>
            <loading-cards />
          </template>
        </Suspense>
      </div>
    </div>
  </blank-layout>
</template>

<script>
import BlankLayout from '@/layouts/blank.vue';
import ConferenceCards from '@/components/conferences/cards/cards.vue';
import LoadingCards from '@/components/conferences/cards/loading.vue';
import { mapActions } from 'vuex';
export default {
  components: {
    BlankLayout,
    LoadingCards,
    ConferenceCards
  },
  created() {
    this.clearCurrent();
  },
  methods: {
    ...mapActions('conferences', ['clearCurrent'])
  }
};
</script>
