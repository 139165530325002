<template>
  <div class="flex flex-col rounded-lg shadow-md hover:shadow-lg hover:cursor-pointer overflow-hidden border border-gray-200">
    <div class="flex-shrink-0">
      <router-link :to="{name: 'dashboard', params: {conference: conference.slug}}">
        <conference-logo
          :conference="conference"
          class="h-40 w-full object-contain"
        />
      </router-link>
    </div>
    <div class="flex-1 bg-white p-6 flex flex-col justify-between border-t border-gray-200">
      <div class="flex-1">
        <router-link
          :to="{name: 'dashboard', params: {conference: conference.slug}}"
          class="block"
        >
          <h3 class="mt-2 text-xl leading-7 font-semibold text-gray-900">
            {{ conference.title }}
          </h3>
          <p
            v-if="hasDescription"
            class="mt-3 text-base leading-6 text-gray-500"
          >
            {{ conference.description }}
          </p>
        </router-link>
      </div>
      <div class="mt-6 flex items-center">
        <div>
          <div class="flex text-sm leading-5 text-gray-500">
            <time :datetime="conference.start">{{ startDate }}</time>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ConferenceLogo from './logo.vue';
import { format } from '@/utils/dates';
import { isEmpty } from '@/utils/lodash';
export default {
  components: {
    ConferenceLogo
  },
  props: {
    conference: {
      type: Object,
      required: true
    }
  },
  computed: {
    hasDescription() {
      return !isEmpty(this.conference.description);
    },
    startDate() {
      return format(this.conference.start, 'dddd, MMMM D YYYY');
    }
  }
};
</script>