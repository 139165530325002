<template>
  <div
    v-for="i in times"
    :key="i"
    class="flex flex-col rounded-lg shadow-lg overflow-hidden animate-pulse"
  >
    <div class="flex-shrink-0">
      <div class="rounded-sm bg-gray-400 h-32 w-full" />
    </div>
    <div class="flex-1 bg-white p-6 flex flex-col justify-between">
      <div class="flex-1 space-y-4 py-1">
        <div class="h-4 bg-gray-400 rounded w-3/4" />
        <div class="space-y-2">
          <div class="h-4 bg-gray-400 rounded" />
          <div class="h-4 bg-gray-400 rounded w-5/6" />
        </div>
      </div>
      <div class="mt-6 items-center">
        <div class="text-sm leading-5 text-gray-500">
          <div class="h-4 bg-gray-400 rounded" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { times } from '@/utils/lodash';
export default {
  props: {
    cards: {
      type: [Number, String],
      default: 1
    }
  },
  computed: {
    times() {
      return times(this.cards);
    }
  }
};
</script>