<template>
  <div class="mt-12 grid gap-5 max-w-lg mx-auto lg:grid-cols-3 lg:max-w-none">
    <card-placeholder cards="2" />
  </div>
</template>

<script>
import CardPlaceholder from '@/components/loaders/card.vue';
export default {
  components: {
    CardPlaceholder
  }
};
</script>